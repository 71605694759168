import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export function useAddressTypes() {
    const store = useStore()
    const addressTypes = computed(() => store.getters.addressTypes)

    const getAddressTypes = () => {
        store.dispatch('fetchAddressTypes')
    }

    onMounted(() => {
        if (addressTypes.value && addressTypes.value.length) {
            return
        }

        getAddressTypes()
    })

    return { addressTypes, getAddressTypes }
}
